<template>
  <div class="flex-col items-center w-full px-5 py-5 bg-white rounded-2xl">
    <div>
      <span class="text-lg font-bold">Export Statistics as CSV</span>
    </div>
    <div class="mb-5">
      <span class="pt-1 text-md">
        For which period would you like to export</span
      >
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('today')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Today
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('last_7days')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Last 7 Days
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('last_30days')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Last 30 Days
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('last_90days')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        Last 90 Days
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('this_year')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        This Year
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a
        href="#"
        @click="exportStats('all_stats')"
        class="pt-1 text-md text-qgo-primary-bg"
      >
        All Time
      </a>
    </div>
    <div class="mt-3">
      <hr />
      <a href="#" @click="cancelExport" class="pt-1 text-red-600 text-md">
        Cancel
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    exportStats(val) {
      this.$store.commit("statistics/setLoading", true);
      // export to csv algo
      // let body = {
      //   stat: val,
      //   download: "stats",
      // };
      // let user = JSON.parse(localStorage.getItem("userData"));
      this.$http
        .get(
          "console/get/statics?waiting_stats=0&require_for=" +
            val +
            "&export=true"
        )
        .then((response) => {
          if (response && response.data) {
            var fileLink = document.createElement("a");
            fileLink.href =
              "data:text/csv;charset=utf-8," +
              encodeURIComponent(response.data);
            let filename = `Q&GO-Stats(${val})${new Date().toISOString().split("T")[0]}.csv`;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.$store.commit("statistics/setLoading", false);
            document.body.removeChild(fileLink);
          } else {
            this.$swal.fire({
            icon: "error",
            title: "It seems there are no stats for selected period",
            timer: 5000,
            toast:true,
            position: "top-end",
            showConfirmButton: false,
          });
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast:true,
            position: "top-end",
            showConfirmButton: false,
          });
          this.$store.commit("statistics/setLoading", false);
        });
      this.$store.commit("statistics/setExportModal", false);
    },
    cancelExport() {
      this.$store.commit("statistics/setExportModal", false);
    },
  },
  computed: {
    exportModal() {
      return this.$store.getters["statistics/exportModal"];
    },
  },
});
</script>
