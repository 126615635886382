<template>
  <div class="w-full h-full" style="position: relative; width:95% !important;">
    <canvas ref="myChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { ref, onMounted, onUpdated } from "vue";

export default {
  props: {
    chartLabels: [Array, String],
    chartData: Array
  },
  setup(props) {
    const myChart = ref(null);

    const setupChart = data => {
      new Chart(myChart.value, {
        type: "line",
        data: {
          labels: data.chartLabels,
          datasets: [
            {
              label: "test line",
              data: data.chartData,
              pointRadius: 0,
              backgroundColor: "blue",
              borderColor: ["rgb(24,215,151)"],
              fill: false,
              lineTension: 0,
              radius: 5
            }
          ]
        },
        options: {
          events: [],
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: "bottom",
            labels: {
              fontColor: "#333",
              fontSize: 16
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  // max: 30,
                  // stepSize: 5
                  precision: 0,
                  maxTicksLimit: 5
                },
                position: "right"
              }
            ]
          }
        }
      });
    };

    onMounted(() => {
      setupChart(props);
    });

    onUpdated(() => {
      setupChart(props);
    });

    return {
      myChart
    };
  }
};
</script>
